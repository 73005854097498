import React, {useEffect} from 'react';
import {Table, Button} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';

const columns = [
  {
    title: 'Заголовок',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Дата добавления',
    dataIndex: 'updateDate',
    key: 'updateDate',
  },
  {
    title: 'Действия с новостью',
    dataIndex: 'actions',
    key: 'actions',
    render: () => (
      <div>
        <Button icon={<DeleteOutlined />} />
      </div>
    ),
  },
];

const dataSource = [
  {
    key: '1',
    title: 'Mike',
    updateDate: 32,
  },

];

export default function News({toggleLoading}) {
  useEffect(() => {
    toggleLoading();
  }, [
  ]);

  return (
    <Table dataSource={dataSource} columns={columns} />
  );
}
