import React, {useEffect, useState} from 'react';
import {Bill} from '@components/Bill/Bill';
import api from '@modules/api';
import {Result} from 'antd';
import {Filters} from '@components/BillFilters/Filters';
import {SearchOutlined} from '@ant-design/icons';
import './styles/style.css';
import style from './styles/style.module.scss';

export const BillsHistory = ({toggleLoading}) => {
  const [bills, setBills] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [firms, setFirms] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [filters, setFilters] = useState({
    mode: 'passed',
    firm: undefined,
    org: undefined,
    exp: undefined,
    author: undefined,
    create: undefined,
    payDate: undefined,
    type: undefined,
    id: '',
    paid: false,
    confirmation: false,
    canceled: false,
  });
  const updateData = (filters) => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });
    getFiltredBills(newParams);
  };

  const setFilterByTitle = (title, data) => {
    const updatedFilters = {};

    if (Array.isArray(title) && Array.isArray(data)) {
      title.forEach((value, index) => {
        updatedFilters[value] = data[index];
      });
    } else {
      updatedFilters[title] = data;
    }

    const newFilters = {...filters, ...updatedFilters};
    setFilters(newFilters);
  };

  const setOrgs = (ids) => {
    if (!ids.length) {
      setFilters({
        ...filters,
        org: undefined,
        type: undefined
      });
      return;
    }
    const types = [];
    ids.forEach((id) => {
      const org = organizations.find((org) => org.id === id);
      if (org) {
        types.push(org.typeOrganization);
      }
    });
    const newFilters = {
      ...filters,
      org: ids,
      type: types
    };
    setFilters(newFilters);
  };

  const idSearch = () => {
    const newParams = new URLSearchParams();
    newParams.set('mode', filters.mode);
    newParams.set('id', filters.id);
    getFiltredBills(newParams);
  };

  const applyFilters = () => {
    updateData(filters);
  };

  const fetchBills = async (params) => {
    const response = await api(`bill/payment?${params}`);
    if (response.status === 200) {
      setBills(response.data);
      if (!response.data.length) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    }
  };

  const getFiltredBills = async (params) => {
    toggleLoading();
    await fetchBills(params);
    toggleLoading();
  };

  const fetchFilters = async () => {
    const response = await api(`bill/filters`);
    if (response) {
      if (response?.status) {
        const [firmsData, sideFirmsData, statesData, expensesData] = response.data;
        if (firmsData.status) {
          setFirms(
            firmsData.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
          );
        }
        if (expensesData.status) {
          setExpenses(expensesData.data);
        }
        const organizationData = [
          ...sideFirmsData.data?.map((val) => ({
            ...val,
            typeOrganization: 'organization',
          })),
          ...statesData.data?.map((val) => ({
            ...val,
            name: val.title,
            typeOrganization: 'state',
          })),
        ].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setOrganizations(organizationData);
      }
    }
  };

  const getData = async () => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });

    await fetchBills(newParams);
  };

  const fetchInitialData = async () => Promise.all([
    fetchFilters(),
    getData()
  ]);

  useEffect(() => {
    (async () => {
      await fetchInitialData();
      toggleLoading();
    })();
  }, []);

  const removeBill = async () => {
    updateData(filters);
  };

  const resetFilters = (title, data) => {
    const updatedFilters = {};

    if (Array.isArray(title) && Array.isArray(data)) {
      title.forEach((value, index) => {
        updatedFilters[value] = data[index];
      });
    } else {
      updatedFilters[title] = data;
    }
    const newFilters = {
      ...filters,
      firm: undefined,
      org: undefined,
      type: undefined,
      exp: undefined,
      author: undefined,
      create: undefined,
      payDate: undefined,
      id: '',
      paid: false,
      confirmation: false,
      canceled: false,
      ...updatedFilters,
    };
    setFilters(newFilters);

    updateData(newFilters);
  };

  return (
    <>
      <div className={style.Container}>
        <div className={style.Cards}>
          {bills.map((el) => (
            <Bill bill={el} forPayment={true} showAuthor={true}
                  toggleLoading={toggleLoading} removeBill={removeBill}/>
          ))}
          {empty &&
            <Result className="no-bills" icon={<SearchOutlined/>} status="info"
                    title="Согласований не найдено"/>}
        </div>
        <div className={style.Filters}>
          <Filters
            mode={'payment'}
            applyFilters={applyFilters}
            passed={true}
            filters={filters}
            firms={firms}
            orgs={organizations}
            expenses={expenses}
            authors={authors}
            setAuthorOptions={setAuthors}
            setOrgs={setOrgs}
            idSearch={idSearch}
            resetFilters={resetFilters}
            setFilterByTitle={setFilterByTitle}
          />
        </div>
      </div>
    </>
  );
};
