import AdminUsers from '@pages/Admin/Users';
import {Firms} from '@pages/Admin/Firms';
import {SideFirms} from '@pages/Admin/SideFirms';
import {Expenses} from '@pages/Admin/Expenses/Expenses';
import {ChaoticChain} from '@pages/Admin/ChaoticChains/ChaoticChain';
import {ChaoticChains} from '@pages/Admin/ChaoticChains';
import {BillsForPayment} from '@pages/BillsForPayment';
import {BillsHistory} from '@pages/BillsHistory';
import AdminStates from '@pages/Admin/States';
import MyBill from '@pages/MyBill';
import {NewsPage} from '@pages/NewsPage';
import CurrentNewsPage from '@pages/NewsPage/CurrentNewPage';
import AdminNewsPage from '@pages/Admin/News';
import AdminCurrentNewPage from '@pages/Admin/News/CurrentNewPage';

const getRoutes = ({isAdmin}) => {
  if (isAdmin) {
    return adminRoutes;
  } return otherRoutes;
};

const adminRoutes = [
  {
    path: '/', exact: true, name: 'Новости', component: NewsPage,
  },
  {
    path: '/:id', exact: true, name: 'Новость', component: CurrentNewsPage,
  },
  {
    path: '/users', exact: true, name: 'Пользователи', component: AdminUsers,
  },
  {
    path: '/firms', exact: true, name: 'Фирмы', component: Firms,
  },
  {
    path: '/side-firms', exact: true, name: 'Сторонние организации', component: SideFirms,
  },
  {
    path: '/expenses', exact: true, name: 'Статьи расходов', component: Expenses,
  },
  {
    path: '/chaotic-chain', exact: true, name: 'Цепочки согласований', component: ChaoticChains,
  },
  {
    path: '/chaotic-chain/:id', exact: true, name: 'Цепочки согласований', component: ChaoticChain,
  },
  {
    path: '/states', exact: true, name: 'Подразделения', component: AdminStates,
  },
  {
    path: '/bills-for-payment', exact: true, name: 'Счета на оплату', component: BillsForPayment,
  },
  {
    path: '/my-bill', exact: true, name: 'Мои счета', component: MyBill,
  },
  {
    path: '/bills-history',
    exact: true,
    name: 'История счетов',
    component: BillsHistory,
  },
  {
    path: '/news', exact: true, name: 'Управление новостями', component: AdminNewsPage,
  },
  {
    path: '/news/:id', exact: true, name: 'Редактирование новости', component: AdminCurrentNewPage,
  },
];

const otherRoutes = [
  {
    path: '/', exact: true, name: 'Новости', component: NewsPage,
  },
  {
    path: '/:id', exact: true, name: 'Новость', component: CurrentNewsPage,
  },
  {
    path: '/my-bill', exact: true, name: 'Счета на оплату', component: MyBill,
  },
  {
    path: '/bills-for-payment', exact: true, name: 'Счета на оплату', component: BillsForPayment,
  },
  {
    path: '/bills-history',
    exact: true,
    name: 'История счетов',
    component: BillsHistory,
  },
];

export default getRoutes;
