import {useContext, useState, useEffect, memo, useCallback} from 'react';
import {Link, Route, Routes, useLocation} from 'react-router-dom';
import {
  Layout,
  Menu,
  theme,
  Spin,
  Typography,
  Drawer,
  Button,
  Row,
  Col,
  Switch,
  Space
} from 'antd';
import UserContext from '@context/UserContext.js';
import getNavigate from '../nav';
import getRoutes from '../routes';
import './style.css';
import styles from './style.module.scss';
import api from '@modules/api';
import {UnorderedListOutlined, UserOutlined} from '@ant-design/icons';

const {
  Content,
  Sider,
  Header
} = Layout;

const {Title} = Typography;

const MemoContent = memo(({
  routesLocal,
  toggleLoading,
  pathname,
  isLoading,
  colorBgContainer,
  borderRadiusLG
}) => (
  <Content
    style={{
      margin: 10,
      padding: 10,
      background: pathname === '/my-bill' || pathname === '/bills-for-payment' || pathname === '/bills-history' ? '#f5f5f5' : colorBgContainer,
      borderRadius: borderRadiusLG,
      flex: 1,
      overflowY: 'auto',
    }}
  >
    <Routes>
      {routesLocal &&
        routesLocal.map((route, idx) => {
          return route.component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={
                <Spin spinning={isLoading}>
                  <Typography.Title level={3} style={{marginTop: 0}}>
                    {route.name}
                  </Typography.Title>
                  <route.component toggleLoading={toggleLoading}
                                   isLoading={isLoading}/>
                </Spin>
              }
            />
          ) : null;
        })}
    </Routes>
  </Content>
));

const DefaultLayout = ({
  logout,
  setUser
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenDrawerProfile, setIsOpenDrawerProfile] = useState(false);
  const [isOpenDrawerMenu, setIsOpenDrawerMenu] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoadingChecked, setIsLoadingChecked] = useState({
    notificationMyBill: false,
    notificationBillConfirm: false,
  });
  const user = useContext(UserContext);
  const location = useLocation();
  const {pathname} = location;
  const {
    token: {
      colorBgContainer,
      borderRadiusLG
    },
  } = theme.useToken();

  const setLinks = (items) => {
    return items?.length
      ? items.map((el) => {
        let children;
        if (el.children?.length) {
          children = setLinks(el.children);
        }
        if (el.url) {
          return {
            ...el,
            children,
            label: <Link to={el.url}>{el.label}</Link>,
          };
        } else {
          return {
            ...el,
            children,
          };
        }
      })
      : [];
  };

  const nav = user ? setLinks(getNavigate(user)) : [];

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (pathname !== '/' && !isLoading) {
      toggleLoading();
    }
    setIsOpenDrawerMenu(false);
  }, [pathname]);

  const getSelectedNav = () => {
    let localNav = nav;
    let selectedKey;
    for (let i = 0; i < localNav.length; i++) {
      if (localNav[i].children?.length) {
        localNav = [
          ...localNav,
          ...localNav[i].children
        ];
      }
      if (localNav[i].url === pathname) {
        selectedKey = localNav[i].key;
        break;
      }
    }

    return [selectedKey];
  };

  const toggleLoading = useCallback(() => setIsLoading((prevState) => !prevState), []);

  const toggleIsOpenDrawerProfile = () => setIsOpenDrawerProfile((prevState) => !prevState);

  const toggleIsOpenDrawerMenu = () => setIsOpenDrawerMenu((prevState) => !prevState);

  const toggleIsLoadingChecked = (type) =>
    setIsLoadingChecked((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));

  const siderStyle = {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    scrollbarWidth: 'thin',
    scrollbarColor: 'unset',
  };

  const layoutStyle = {
    transitionProperty: 'margin-inline-start',
    transitionDuration: '.3s',
  };

  const logoStyles = {
    width: '188px',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '7px',
    position: 'fixed',
    overflow: 'hidden',
    top: 0,
    left: 0,
    zIndex: 100,
    background: colorBgContainer,
  };

  const setChecked = async (checked, type) => {
    toggleIsLoadingChecked(type);
    await api('user/update_self', {
      method: 'POST',
      body: {
        [type]: checked,
      },
    });
    setUser({
      ...user,
      [type]: checked,
    });
    toggleIsLoadingChecked(type);
  };

  return (
    <Layout style={{height: '100vh'}}>
      <Sider trigger={null} className={styles.Sider}
             style={{background: colorBgContainer, ...siderStyle}}>
        <div style={{
          position: 'relative',
          width: 'inherit'
        }}>
          <div style={logoStyles}>
            <img src="/logo.svg" alt="logo"/>
          </div>
          <Menu mode="inline" selectedKeys={getSelectedNav()} items={nav}
                className={styles.Menu}/>
          <div className={styles.MenuFioStyles}
               style={{background: colorBgContainer}}>
            <Typography.Text ellipsis style={{cursor: 'pointer'}}
                             onClick={toggleIsOpenDrawerProfile}>
              <UserOutlined/> Профиль
            </Typography.Text>
          </div>
        </div>
      </Sider>
      <Layout className={styles.Layout} style={{
        display: 'flex',
        flexDirection: 'column', ...layoutStyle
      }}>
        <Header className={styles.Header}>
          <div style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <img src="/logo.svg" alt="logo"/>
          </div>
          <Button size="large" color="default" variant="text"
                  onClick={toggleIsOpenDrawerMenu}
                  icon={<UnorderedListOutlined/>}/>
          <Drawer
            open={isOpenDrawerMenu}
            styles={{
              body: {
                marginBottom: '50px',
                scrollbarWidth: 'thin',
                scrollbarColor: 'unset'
              },
              header: {padding: '31px 24px'}
            }}
            onClose={toggleIsOpenDrawerMenu}
            width={500}
          >
            <div style={{
              position: 'relative',
              width: 'inherit'
            }}>
              <Menu mode="inline" selectedKeys={getSelectedNav()} items={nav}/>
              <div className={styles.DrawerFioStyles}
                   style={{background: colorBgContainer}}>
                <Typography.Text ellipsis style={{cursor: 'pointer'}}
                                 onClick={toggleIsOpenDrawerProfile}>
                  <UserOutlined/> Профиль
                </Typography.Text>
              </div>
            </div>
          </Drawer>
        </Header>
        <MemoContent
          toggleLoading={toggleLoading}
          routesLocal={user ? getRoutes(user) : []}
          pathname={pathname}
          isLoading={isLoading}
          colorBgContainer={colorBgContainer}
          borderRadiusLG={borderRadiusLG}
        />
      </Layout>
      <Drawer
        title={
          <Title level={3} style={{margin: 0}}>
            Профиль
          </Title>
        }
        placement={width <= 900 ? 'bottom' : 'left'}
        styles={{
          body: {padding: '0 24px'},
          header: {padding: '31px 24px'}
        }}
        onClose={toggleIsOpenDrawerProfile}
        zIndex={10000}
        width={width <= 900 ? '100%' : 375}
        open={isOpenDrawerProfile}
      >
        <Space direction={'vertical'}>
          <Row>
            <Col span={24}>
              <Title style={{margin: '0.5em 0'}} level={5}>
                {user?.fullName || ''}
              </Title>
            </Col>
            <Col span={24}>
              <Button onClick={() => logout()} danger={true} variant="outlined">
                Выйти из профиля
              </Button>
            </Col>
            <Col span={24}>
              <Title level={4}>Настройки уведомлений</Title>
            </Col>
            <Col span={19}>Уведомления "Мои счета"</Col>
            <Col span={4} offset={1}>
              <Switch
                loading={isLoadingChecked.notificationMyBill}
                checked={user?.notificationMyBill}
                onChange={(checked) => setChecked(checked, 'notificationMyBill')}
              />
            </Col>
          </Row>
          <Row>
            <Col span={19}>Уведомления "Счета на оплату"</Col>
            <Col span={4} offset={1}>
              <Switch
                loading={isLoadingChecked.notificationBillConfirm}
                checked={user?.notificationBillConfirm}
                onChange={(checked) => setChecked(checked, 'notificationBillConfirm')}
              />
            </Col>
          </Row>
        </Space>
      </Drawer>
    </Layout>
  );
};

export default DefaultLayout;
