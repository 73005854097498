import React, {
  UserOutlined,
  RadarChartOutlined,
  DollarCircleOutlined,
  BranchesOutlined,
  SplitCellsOutlined,
  BankOutlined,
  ExceptionOutlined,
  FileSyncOutlined,
} from '@ant-design/icons';

const getNuv = ({isAdmin}) => {
  if (isAdmin) {
    return adminNav;
  } return otherNav;
};

const adminNav = [
  {
    key: '0',
    icon: <ExceptionOutlined />,
    label: 'Новости',
    url: '/',
  },
  {
    key: '1',
    icon: <ExceptionOutlined />,
    label: 'Счета на оплату',
    url: '/bills-for-payment',
  },
  {
    key: '2',
    icon: <BankOutlined />,
    label: 'История счетов',
    url: '/bills-history',
  },
  {
    key: '3',
    icon: <FileSyncOutlined />,
    label: 'Мои счета',
    url: '/my-bill',
  },
  {
    key: '5',
    icon: <UserOutlined />,
    label: 'Управление',
    children: [
      {
        key: '6',
        icon: <UserOutlined />,
        label: 'Пользователи',
        url: '/users',
      },
      {
        key: '7',
        icon: <RadarChartOutlined />,
        label: 'Фирмы',
        url: '/firms',
      },
      {
        key: '8',
        icon: <RadarChartOutlined />,
        label: 'Сторонние организации',
        url: '/side-firms',
      },
      {
        key: '9',
        icon: <DollarCircleOutlined />,
        label: 'Статьи расходов',
        url: '/expenses',
      },
      {
        key: '10',
        icon: <BranchesOutlined />,
        label: 'Цепочки согласований',
        url: '/chaotic-chain',
      },
      {
        key: '11',
        icon: <SplitCellsOutlined />,
        label: 'Подразделения',
        url: '/states',
      },
      {
        key: '12',
        icon: <SplitCellsOutlined />,
        label: 'Управление новостями',
        url: '/news',
      },
    ],
  },
];

const otherNav = [
  {
    key: '0',
    icon: <ExceptionOutlined />,
    label: 'Новости',
    url: '/',
  },
  {
    key: '1',
    icon: <ExceptionOutlined />,
    label: 'Счета на оплату',
    url: '/bills-for-payment',
  },
  {
    key: '2',
    icon: <BankOutlined />,
    label: 'История счетов',
    url: '/bills-history',
  },
  {
    key: '3',
    icon: <FileSyncOutlined />,
    label: 'Мои счета',
    url: '/my-bill',
  },
];

export default getNuv;
