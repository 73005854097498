import React from 'react';
import {
  Card, Typography, Button,
} from 'antd';
import style from './styles/styles.module.scss';
import {CalendarOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

export function NewsCard({id, title, text, date}) {
  const navigate = useNavigate();
  return (
    <Card className={style.Card}>
      <div className={style.Body}>
        <Typography className={style.Title}>
          {title}
        </Typography>
        <div className={style.Text}>
          {text}
        </div>
      </div>
      <div className={style.Footer}>
        <Button onClick={() => { navigate(`/${id}`); }} style={{padding: 0, height: 'fit-content'}} color="primary" variant="link">Подробнее</Button>
        <div className={style.Date}>
          <CalendarOutlined height={16} width={16} color="#00000073" />
          <Typography className={style.DateText}>{date}</Typography>
        </div>
      </div>
    </Card>
  );
}
