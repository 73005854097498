import React, {useState, useEffect} from 'react';
import style from './styles/styles.module.scss';
import {
  Button, Card, Typography,
} from 'antd';
import {useNavigate} from 'react-router-dom';
import {CalendarOutlined, ArrowLeftOutlined} from '@ant-design/icons';

export default function CurrentNewPage({toggleLoading}) {
  const navigate = useNavigate();
  const [
    data,
    // setData,
  ] = useState({
    id: 1,
    title: 'График дежурных специалистов отдела технической поддержки в праздничный день 4 ноября',
    text: `12 июня Минфин США объявил о новых запретах для России, касающихся облачных сервисов. Согласно постановлению, запрещено:

- Оказывать IT-консалтинговые и проектировочные услуги.
- Оказывать IT-поддержку для программ, используемых в управлении предприятиями, проектировании и производстве.

Список ресурсов, подверженных возможной блокировке внутри новости.
12 июня Минфин США объявил о новых запретах для России, касающихся облачных сервисов. Согласно постановлению, запрещено:

- Оказывать IT-консалтинговые и проектировочные услуги.
- Оказывать IT-поддержку для программ, используемых в управлении предприятиями, проектировании и производстве.

Список ресурсов, подверженных возможной блокировке внутри новости.
12 июня Минфин США объявил о новых запретах для России, касающихся облачных сервисов. Согласно постановлению, запрещено:

- Оказывать IT-консалтинговые и проектировочные услуги.
- Оказывать IT-поддержку для программ, используемых в управлении предприятиями, проектировании и производстве.

Список ресурсов, подверженных возможной блокировке внутри новости.
12 июня Минфин США объявил о новых запретах для России, касающихся облачных сервисов. Согласно постановлению, запрещено:

- Оказывать IT-консалтинговые и проектировочные услуги.
- Оказывать IT-поддержку для программ, используемых в управлении предприятиями, проектировании и производстве.

Список ресурсов, подверженных возможной блокировке внутри новости.
12 июня Минфин США объявил о новых запретах для России, касающихся облачных сервисов. Согласно постановлению, запрещено:

- Оказывать IT-консалтинговые и проектировочные услуги.
- Оказывать IT-поддержку для программ, используемых в управлении предприятиями, проектировании и производстве.

Список ресурсов, подверженных возможной блокировке внутри новости.
12 июня Минфин США объявил о новых запретах для России, касающихся облачных сервисов. Согласно постановлению, запрещено:

- Оказывать IT-консалтинговые и проектировочные услуги.
- Оказывать IT-поддержку для программ, используемых в управлении предприятиями, проектировании и производстве.

Список ресурсов, подверженных возможной блокировке внутри новости.
12 июня Минфин США объявил о новых запретах для России, касающихся облачных сервисов. Согласно постановлению, запрещено:

- Оказывать IT-консалтинговые и проектировочные услуги.
- Оказывать IT-поддержку для программ, используемых в управлении предприятиями, проектировании и производстве.

Список ресурсов, подверженных возможной блокировке внутри новости.
12 июня Минфин США объявил о новых запретах для России, касающихся облачных сервисов. Согласно постановлению, запрещено:

- Оказывать IT-консалтинговые и проектировочные услуги.
- Оказывать IT-поддержку для программ, используемых в управлении предприятиями, проектировании и производстве.

Список ресурсов, подверженных возможной блокировке внутри новости.
`,
    date: '22.01.2023',
  });

  useEffect(() => {
    toggleLoading();
  }, [
  ]);

  return (
    <Card className={style.Card}>
      <Typography className={style.Title}>
        {data.title}
      </Typography>
      <div className={style.Header}>
        <div className={style.Date}>
          <CalendarOutlined height={16} width={16} color="#00000073" />
          <Typography className={style.DateText}>{data.date}</Typography>
        </div>
      </div>
      <div className={style.Body}>
        <div className={style.Text}>
          {data.text}
        </div>
        <Button onClick={() => navigate('/')}>
          <ArrowLeftOutlined />
          К списку новостей
        </Button>
      </div>
    </Card>
  );
}
