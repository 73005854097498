import React, {useState, useEffect} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import MDEditor, {selectWord} from '@uiw/react-md-editor';
import {Input} from 'antd';

export default function CurrentNewsPage({toggleLoading}) {
  const [
    data,
    setData,
  ] = useState({
    title: 'New',
    text: '',
  });

  useEffect(() => {
    toggleLoading();
  }, [
  ]);

  const changeFormValue = (key, value) => {
    setData((prev) => ({...prev, [key]: value}));
  };

  return (
    <div>
      <Input value={data.title} placeholder="Заголовок" onChange={(e) => changeFormValue('title', e.target.value)} />
      <div data-color-mode="light">
        <MDEditor height={200} value={data.text} onChange={(e) => changeFormValue('text', e)} />
      </div>
    </div>
  );
}
