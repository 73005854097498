import React, {useEffect, useState} from 'react';
import {NewsCard} from '@components/NewsCard/NewsCard';

export function NewsPage({toggleLoading}) {
  const [
    news,
    // setNews,
  ] = useState([
    {
      id: 1,
      title: 'График дежурных специалистов отдела технической поддержки в праздничный день 4 ноября',
      text: `12 июня Минфин США объявил о новых запретах для России, касающихся облачных сервисов. Согласно постановлению, запрещено:

- Оказывать IT-консалтинговые и проектировочные услуги.
- Оказывать IT-поддержку для программ, используемых в управлении предприятиями, проектировании и производстве.

Список ресурсов, подверженных возможной блокировке внутри новости.`,
      date: '22.01.2023',
    },
    {
      id: 2,
      title: 'График дежурных специалистов отдела технической поддержки в праздничный день 4 ноября',
      text: `12 июня Минфин США объявил о новых запретах для России, касающихся облачных сервисов. Согласно постановлению, запрещено:

- Оказывать IT-консалтинговые и проектировочные услуги.
- Оказывать IT-поддержку для программ, используемых в управлении предприятиями, проектировании и производстве.

Список ресурсов, подверженных возможной блокировке внутри новости.`,
      date: '22.01.2023',
    },
  ]);

  useEffect(() => {
    toggleLoading();
  }, [
  ]);

  return (
    news.map((value) => (
      <NewsCard
        key={value.id}
        date={value.date}
        id={value.id}
        text={value.text}
        title={value.title}
      />
    ))
  );
}
