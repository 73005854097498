import React, {useEffect, useState} from 'react';
import {Bill} from '@components/Bill/Bill';
import api from '@modules/api';
import {
  Result, Space, Button,
  Typography,
} from 'antd';
import {Filters} from '@components/BillFilters/Filters';
import {SearchOutlined} from '@ant-design/icons';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import './styles/style.css';
import {isMobile} from 'react-device-detect';
import style from './styles/style.module.scss';

export function BillsForPayment({toggleLoading}) {
  const [
    bills,
    setBills,
  ] = useState([
  ]);
  const [
    selected,
    setSelected,
  ] = useState(new Map());
  const [
    organizations,
    setOrganizations,
  ] = useState([
  ]);
  const [
    firms,
    setFirms,
  ] = useState([
  ]);
  const [
    expenses,
    setExpenses,
  ] = useState([
  ]);
  const [
    authors,
    setAuthors,
  ] = useState([
  ]);
  const [
    empty,
    setEmpty,
  ] = useState(false);
  const [
    downloadPending,
    setDownloadPending,
  ] = useState(false);
  const [
    filters,
    setFilters,
  ] = useState({
    mode: 'forPass',
    firm: undefined,
    org: undefined,
    exp: undefined,
    author: undefined,
    create: undefined,
    nearestDate: undefined,
    payDate: undefined,
    type: undefined,
    id: '',
    paid: false,
    confirmation: false,
    canceled: false,
  });
  const updateData = (filtersLocal) => {
    const newParams = new URLSearchParams();
    Object.keys(filtersLocal).forEach((key) => {
      if (filtersLocal[key] && filtersLocal[key] !== 0) {
        newParams.set(key, filtersLocal[key]);
      }
    });
    getFiltredBills(newParams);
    setSelected(new Map());
  };

  const setFilterByTitle = (title, data) => {
    const updatedFilters = {};

    if (Array.isArray(title) && Array.isArray(data)) {
      title.forEach((value, index) => {
        updatedFilters[value] = data[index];
      });
    } else {
      updatedFilters[title] = data;
    }

    const newFilters = {...filters, ...updatedFilters};
    setFilters(newFilters);
  };

  const setOrgs = (ids) => {
    if (!ids.length) {
      setFilters({
        ...filters, org: undefined, type: undefined,
      });
      return;
    }
    const types = [
    ];
    ids.forEach((id) => {
      const org = organizations.find((orgValue) => orgValue.id === id);
      if (org) {
        types.push(org.typeOrganization);
      }
    });
    const newFilters = {
      ...filters, org: ids, type: types,
    };
    setFilters(newFilters);
  };

  const idSearch = () => {
    const newParams = new URLSearchParams();
    newParams.set('mode', filters.mode);
    newParams.set('id', filters.id);
    getFiltredBills(newParams);
  };

  const applyFilters = () => {
    updateData(filters);
  };

  const handleSelectBill = (id) => {
    if (selected.has(id)) {
      setSelected((prev) => {
        const newObj = new Map(prev);
        newObj.delete(id);
        return newObj;
      });
    } else {
      setSelected((prev) => {
        const newObj = new Map(prev);
        newObj.set(id, true);
        return newObj;
      });
    }
  };

  const fetchBills = async (params) => {
    const response = await api(`bill/payment?${params}`);
    if (response.status === 200) {
      setBills(response.data);
      if (!response.data.length) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    }
  };

  const getFiltredBills = async (params) => {
    toggleLoading();
    await fetchBills(params);
    toggleLoading();
  };

  const downloadBills = async () => {
    setDownloadPending(true);
    await api(
      'bill/download',
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: [
          ...selected.keys(),
        ],
      },
      true,
    )
      .then((response) => response.data.json())
      .then((data) => {
        data.forEach((value) => {
          const blob = new Blob([
            Uint8Array.from(atob(value.content), (c) => c.charCodeAt(0)),
          ], {type: 'application/octet-stream'});
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = value.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        });
      });
    setDownloadPending(false);
  };

  const fetchFilters = async () => {
    const response = await api('bill/filters');
    if (response) {
      if (response?.status) {
        const [
          firmsData,
          sideFirmsData,
          statesData,
          expensesData,
        ] = response.data;
        if (firmsData.status) {
          setFirms(
            firmsData.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            }),
          );
        }
        if (expensesData.status) {
          setExpenses(expensesData.data);
        }
        const organizationData = [
          ...sideFirmsData.data?.map((val) => ({
            ...val,
            typeOrganization: 'organization',
          })),
          ...statesData.data?.map((val) => ({
            ...val,
            name: val.title,
            typeOrganization: 'state',
          })),
        ].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setOrganizations(organizationData);
      }
    }
  };

  const getData = async () => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });

    await fetchBills(newParams);
  };

  const fetchInitialData = async () => Promise.all([
    fetchFilters(),
    getData(),
  ]);

  useEffect(() => {
    (async () => {
      await fetchInitialData();
      toggleLoading();
    })();
  }, [
  ]);

  const editRequestCallback = (id) => {
    const index = bills.findIndex((val) => val.id === id);
    setBills([
      ...bills.slice(0, index),
      ...bills.slice(index + 1),
    ]);
  };

  const removeBill = async () => {
    updateData(filters);
  };

  const resetFilters = (title, data) => {
    const updatedFilters = {};

    if (Array.isArray(title) && Array.isArray(data)) {
      title.forEach((value, index) => {
        updatedFilters[value] = data[index];
      });
    } else {
      updatedFilters[title] = data;
    }
    const newFilters = {
      ...filters,
      firm: undefined,
      org: undefined,
      type: undefined,
      exp: undefined,
      author: undefined,
      create: undefined,
      nearestDate: undefined,
      payDate: undefined,
      id: '',
      paid: false,
      confirmation: false,
      canceled: false,
      ...updatedFilters,
    };
    setFilters(newFilters);

    updateData(newFilters);
  };

  return (
    <>
      <Space style={{marginBottom: '15px'}} direction="horizontal">

        {selected.size ? (
          <Space>
            <Button
              style={{padding: 10}}
              danger
              onClick={() => {
                setSelected(new Map());
              }}
            >
              Отменить выбор
            </Button>
          </Space>
        ) : ''}
        <Space>
          <Button
            style={{padding: 10}}
            onClick={() => {
              const newObj = new Map();
              bills.forEach((val) => {
                newObj.set(val.id, true);
              });
              setSelected(newObj);
            }}
          >
            Выбрать все
          </Button>
        </Space>
        <Space>
          <Button style={{padding: 10}} disabled={!selected.size} loading={downloadPending} type="primary" onClick={downloadBills}>
            Скачать
          </Button>
        </Space>
        {selected.size && !isMobile ? (
          <Space>
            <Typography style={{fontWeight: 500}}>
              Выбрано счетов:
              {' '}
              {selected.size}
            </Typography>
          </Space>
        ) : ''}

      </Space>

      <div className={style.Container}>
        <div className={style.Cards}>
          <TransitionGroup>
            {bills.map((el) => (
              <CSSTransition
                key={`${el.id}_${el.billConfirmations.findLast((val) => val.status === 'new')?.user.id}`}
                timeout={500}
                classNames="item"
              >
                <Bill
                  selectMode
                  selected={selected.has(el.id)}
                  onSelect={handleSelectBill}
                  editRequestCallback={editRequestCallback}
                  bill={el}
                  forPayment
                  showAuthor
                  toggleLoading={toggleLoading}
                  removeBill={removeBill}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
          {empty
            && (
              <Result
                className="no-bills"
                icon={<SearchOutlined />}
                status="info"
                title="Согласований не найдено"
              />
            )}
        </div>
        <div className={style.Filters}>
          <Filters
            mode="payment"
            applyFilters={applyFilters}
            passed={false}
            filters={filters}
            firms={firms}
            orgs={organizations}
            expenses={expenses}
            authors={authors}
            setAuthorOptions={setAuthors}
            setOrgs={setOrgs}
            idSearch={idSearch}
            resetFilters={resetFilters}
            setFilterByTitle={setFilterByTitle}
          />
        </div>
      </div>
    </>
  );
}
